<template>
    <div class="main">
        <saved-template-sidebar @selectTemplate="selectTemplate($event)" @updateInput="search = $event" />
        <section class="toolbar disableSelection" v-if="filesave">
            <ul class="list-group list-group-horizontal toolbar_actions">
                <li class="red">
                    <b-icon icon="x" font-scale="1"></b-icon>
                </li>
                <li class="base">
                    <b-icon icon="dash" font-scale="1"></b-icon>
                </li>
                <li class="green">
                    <b-icon icon="arrows-angle-expand" font-scale="1" v-on:click="toggleFullscreen"></b-icon>
                </li>
            </ul>
            <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret>
                <template v-slot:button-content>
                    <span class="fileBtn">File</span>
                </template>
                <b-dropdown-item @click="opanSaveModal">Save</b-dropdown-item>
                <b-dropdown-item @click="openSaveAsModal">Save As New</b-dropdown-item>
            </b-dropdown>
        </section>
        <section class="toolbox disableSelection">
            <ul class="list-group list-group-horizontal toolbox-list flex-wrap">
                <li style="width: 490px" class="my-lg-3 my-md-3">
                    <div class="row no-padding">
                        <div class="col-md-4">
                            <select @change="handleChangeFontFamily">
                                <option>Arial</option>
                                <option>Menio</option>
                            </select>
                        </div>
                        <div class="col-md-3">
                            <select @click.prevent="onTextSizeChange" v-model="fontSize">
                                <option v-for="row in 50" :key="row">
                                    {{ row }}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-5">
                            <select @click="onTextTransformChange" v-model="textCase">
                                <option value="lowercase">lowercase</option>
                                <option value="uppercase">UPPERCASE</option>
                                <option value="capitalize">Capitalise Each Word</option>
                            </select>
                        </div>
                    </div>
                    <div class="row no-padding">
                        <div class="col-md-12">
                            <div class="formating-icons" v-b-popover.hover.top="'Bold'">
                                <label class="checkbox-btn">
                                    <input type="checkbox" v-on:change="toggleCmd('bold', false)" v-model="bold" />
                                    <span class="checkmark">
                                        <b-icon icon="type-bold" font-scale="1"></b-icon>
                                    </span>
                                </label>
                            </div>
                            <div class="formating-icons" v-b-popover.hover.top="'Italic'">
                                <label class="checkbox-btn">
                                    <input type="checkbox" v-on:change="toggleCmd('italic', false)" v-model="italic" />
                                    <span class="checkmark">
                                        <b-icon icon="type-italic" font-scale="1"></b-icon>
                                    </span>
                                </label>
                            </div>

                            <div class="formating-icons" v-b-popover.hover.top="'Underline'">
                                <label class="checkbox-btn">
                                    <input type="checkbox" v-on:change="toggleCmd('underline', false)" v-model="underline" />
                                    <span class="checkmark">
                                        <b-icon icon="type-underline" font-scale="1"></b-icon>
                                    </span>
                                </label>
                            </div>
                            <div class="formating-icons" v-b-popover.hover.top="'Strikethrough'">
                                <label class="checkbox-btn">
                                    <input type="checkbox" v-on:change="toggleCmd('strikethrough', false)" v-model="strikethrough" />
                                    <span class="checkmark">
                                        <b-icon icon="type-strikethrough" font-scale="1"></b-icon>
                                    </span>
                                </label>
                            </div>
                            <div class="formating-icons" v-b-popover.hover.top="'Superscript'">
                                <label class="checkbox-btn">
                                    <input type="checkbox" v-on:change="toggleCmd('super', false)" v-model="superv" />
                                    <span class="checkmark"> X<sup>2</sup> </span>
                                </label>
                            </div>
                            <div class="formating-icons" v-b-popover.hover.top="'Subscript'">
                                <label class="checkbox-btn">
                                    <input type="checkbox" v-on:change="toggleCmd('sub', false)" />
                                    <span class="checkmark"> X<sub>2</sub> </span>
                                </label>
                            </div>
                            <div class="formating-icons" v-b-popover.hover.top="'link'">
                                <label class="checkbox-btn">
                                    <input type="checkbox" v-on:change="toggleCmd('createLink', false)" v-model="sub" />
                                    <span class="checkmark">
                                        <b-icon-link font-scale="1"></b-icon-link>
                                    </span>
                                </label>
                            </div>

                            <div class="formating-icons" style="display: flex; border: solid 1px grey; border-radius: 4px" v-b-popover.hover.top="'text color'">
                                <!-- A -->
                                <label class="checkbox-btn">
                                    <span class="checkmark">
                                        <b-icon-pencil font-scale="1" @click="toggleCmd('foreColor', false)"></b-icon-pencil>
                                    </span>
                                </label>
                                <span class="foreColor"></span>
                            </div>

                            <div class="formating-icons" style="display: flex; border: solid 1px grey; margin-left: 5px; border-radius: 4px" v-b-popover.hover.top="'background color'">
                                <!-- A -->
                                <label class="checkbox-btn">
                                    <span class="checkmark">
                                        <b-icon-droplet-fill font-scale="1" @click="toggleCmd('bgColor', false)"></b-icon-droplet-fill>
                                    </span>
                                </label>
                                <span class="backColor"></span>
                            </div>

                            <!-- 
                        <div class="formating-icons">
                             <b-dropdown size="lg"  variant="link" toggle-class="text-decoration-none" no-caret>
                                <template v-slot:button-content>
                                    <span class="checkmark">
                                        <b-icon icon="grid-fill" font-scale="1" v-bind:style="{color: textColor}" class="text-shadow" v-b-popover.hover.top="'Text Colour'"></b-icon>
                                    </span>
                                </template>
                                <sketch-picker :value="textColor" @input="updateColor($event, 'text')" />
                            </b-dropdown>
                        </div> -->

                            <div class="formating-icons">
                                <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret>
                                    <template v-slot:button-content>
                                        <span class="checkmark">
                                            <b-icon icon="grid-fill" font-scale="1" v-bind:style="{color: bgColor}" class="text-shadow" v-b-popover.hover.top="'Background Colour'"></b-icon>
                                        </span>
                                    </template>
                                    <sketch-picker :preset-colors="['#1a8015', '#edbf2e', '#ff0000']" :value="bgColor" @input="updateColor($event, 'bg')" />
                                </b-dropdown>
                                <div class="formating-icons"></div>
                            </div>

                            <div class="formating-icons" v-b-popover.hover.top="'Font weight'">
                                <!-- <label style="font-size:0.7em;color:white">Font Weight</label> -->
                                <select @click="changeFontWeight" v-model="activeFontWeight">
                                    <option v-for="weight in weights" :key="weight">
                                        {{ weight }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </li>
                <li style="width: 230px" class="my-lg-3 my-md-3">
                    <div class="row no-padding">
                        <div class="col-md-12">
                            <div class="formating-icons">
                                <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret>
                                    <template v-slot:button-content>
                                        <span class="checkmark">
                                            <b-icon icon="table" font-scale="1" v-b-popover.hover.top="'Table'"></b-icon>
                                        </span>
                                    </template>
                                    <table class="table table-bordered table-auto" id="edit_table">
                                        <tr v-for="row in 5" :key="row">
                                            <td @mouseover="onMouse(row, col, $event)" v-for="col in 10" :key="col" v-on:click="addTable"></td>
                                        </tr>
                                    </table>

                                    <div class="col-md-12">
                                        <b-form-checkbox style="margin-left: 30px; color: #fff" id="table_checkbox" v-model="isHeader" name="table_checkbox" value="0" unchecked-value="1">with header </b-form-checkbox>
                                    </div>
                                </b-dropdown>
                            </div>
                            <div class="formating-icons">
                                <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret>
                                    <template v-slot:button-content>
                                        <span class="checkmark">
                                            <b-icon icon="image" font-scale="1" v-b-popover.hover.top="'Image'"></b-icon>
                                        </span>
                                    </template>
                                    <b-dropdown-form style="width: 280px">
                                        <b-form-file id="img_file" size="sm" style="margin-bottom: 10px"></b-form-file>
                                        <span style="text-align: center; display: block; color: #fff">OR</span>
                                        <b-form-input style="margin-top: 10px" id="image_url" size="sm" placeholder="image url"></b-form-input>
                                        <b-button variant="primary" size="sm" @click="addImage">Add Image</b-button>
                                    </b-dropdown-form>
                                </b-dropdown>
                            </div>
                            <div class="formating-icons" v-b-popover.hover.top="'Overlay'">
                                <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret>
                                    <template v-slot:button-content>
                                        <span class="checkmark">
                                            <b-icon icon="images" font-scale="1" @click="imageOver"></b-icon>
                                        </span>
                                    </template>
                                </b-dropdown>
                            </div>
                            <div class="formating-icons">
                                <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret>
                                    <template v-slot:button-content>
                                        <span class="checkmark">
                                            <b-icon icon="list-ul" font-scale="1" v-b-popover.hover.top="'Unordered List'"></b-icon>
                                        </span>
                                    </template>
                                    <b-dropdown-item @click="addList(0, 'circle')">Circle</b-dropdown-item>
                                    <b-dropdown-item @click="addList(0, 'disc')">Disc</b-dropdown-item>
                                    <b-dropdown-item @click="addList(0, 'square')">Square</b-dropdown-item>
                                </b-dropdown>
                            </div>
                            <div class="formating-icons">
                                <b-dropdown size="lg" toggle-class="text-decoration-none" no-caret>
                                    <template v-slot:button-content>
                                        <span class="checkmark">
                                            <b-icon icon="list-ol" font-scale="1" v-b-popover.hover.top="'Ordered List'"></b-icon>
                                        </span>
                                    </template>
                                    <b-dropdown-item @click="addList(-1, '')">Remove List</b-dropdown-item>
                                    <b-dropdown-item @click="addList(1, 'decimal')">Numbers</b-dropdown-item>
                                    <b-dropdown-item @click="addList(1, 'lower-alpha')">Alphabete lowercase</b-dropdown-item>
                                    <b-dropdown-item @click="addList(1, 'upper-alpha')">Alphabete uppercase</b-dropdown-item>
                                    <b-dropdown-item @click="addList(1, 'lower-roman')">Roman lowercase</b-dropdown-item>
                                    <b-dropdown-item @click="addList(1, 'upper-roman')">Roman uppercase</b-dropdown-item>
                                </b-dropdown>
                            </div>
                            <div class="formating-icons" v-b-popover.hover.top="'Align Left'">
                                <label class="checkbox-btn">
                                    <input type="checkbox" v-on:change="justifyContent('left')" v-model="j_left" />
                                    <span class="checkmark">
                                        <b-icon icon="justify-left" font-scale="1"></b-icon>
                                    </span>
                                </label>
                            </div>
                            <div class="formating-icons" v-b-popover.hover.top="'Align Center'">
                                <label class="checkbox-btn">
                                    <input type="checkbox" v-on:change="justifyContent('center')" v-model="j_center" />
                                    <span class="checkmark">
                                        <b-icon icon="text-center" font-scale="1"></b-icon>
                                    </span>
                                </label>
                            </div>
                            <div class="formating-icons" v-b-popover.hover.top="'Align Right'">
                                <label class="checkbox-btn">
                                    <input type="checkbox" v-on:change="justifyContent('right')" v-model="j_right" />
                                    <span class="checkmark">
                                        <b-icon icon="justify-right" font-scale="1"></b-icon>
                                    </span>
                                </label>
                            </div>
                            <div class="formating-icons" v-b-popover.hover.top="'Text Justify'">
                                <label class="checkbox-btn">
                                    <input type="checkbox" v-on:change="justifyContent('justify')" v-model="j_all" />
                                    <span class="checkmark">
                                        <b-icon icon="justify" font-scale="1"></b-icon>
                                    </span>
                                </label>
                            </div>
                            <div class="formating-icons" v-b-popover.hover.top="'Fit Width Image'">
                                <span class="checkmark" @click="fitImg()">
                                    <b-icon icon="aspect-ratio" font-scale="1"></b-icon>
                                </span>
                            </div>
                            <div class="formating-icons" v-b-popover.hover.top="'Rotate'">
                                <span class="checkmark" @click="rotateText()">
                                    <b-icon icon="arrow-counterclockwise" font-scale="1"></b-icon>
                                </span>
                            </div>
                        </div>
                    </div>
                </li>
                <li style="width: 320px" class="my-lg-3 my-md-3">
                    <div class="row no-padding">
                        <div class="col-md-6">
                            <div class="placeholder-box" @click="toggleModel(0)">
                                <b-icon icon="cursor-text" font-scale="1"></b-icon>
                                Text Placeholder
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="placeholder-box" @click="toggleModel(1)">
                                <b-icon icon="card-list" font-scale="1" style="margin-right: `5px`"></b-icon>
                                List Placeholder
                            </div>
                        </div>

                        <div class="col-md-7">
                            <div class="placeholder-box" @click="toggleModel(3)">
                                <b-icon icon="grid1x2" font-scale="1" style="margin-right: 5px"></b-icon>
                                Saved Social Templates
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="placeholder-box" @click="removeStyle()">
                                <b-icon icon="x-circle-fill" font-scale="1" style="margin-right: 5px"></b-icon>
                                Remove Style
                            </div>
                        </div>
                        <!-- <div class="col-md-5" v-if="$route.name !== 'Editor'">
              <select
                v-model="gotopage"
                @change.prevent="handleGoToPage"
                class="placeholder-box"
              >
                <option value="" disabled>Go to page</option>
                <option v-for="row in allPages" :key="row">
                  {{ row }}
                </option>
              </select>
            </div> -->
                    </div>
                </li>
                <li style="width: auto; border-right: 0" class="my-lg-3 my-md-3">
                    <div class="row" style="margin-top: 10px; align-items: flex-start">
                        <div class="tw-px-4" style="border-right: 1px solid #3e3d3d; max-width: 6rem">
                            <div class="formating-icons margin-icon" @click="openIcons">
                                <b-icon icon="app-indicator" font-scale="1.2"></b-icon>
                                <p>Icons</p>
                            </div>
                        </div>
                        <div class="tw-px-4" style="border-right: 1px solid #3e3d3d; max-width: 6rem">
                            <div class="formating-icons margin-icon" @click="openSocialList">
                                <b-icon icon="app-indicator" font-scale="1.2"></b-icon>
                                <p>Social Icons</p>
                            </div>
                        </div>
                        <div class="tw-px-4" style="border-right: 1px solid #3e3d3d; max-width: 6rem">
                            <div class="formating-icons margin-icon" @click="toggleMargins">
                                <b-icon icon="layout-wtf" font-scale="1.2"></b-icon>
                                <p style="font-size: 12px">Toggle Margins</p>
                            </div>
                        </div>
                        <div class="tw-px-4" style="border-right: 1px solid #3e3d3d; max-width: 6rem">
                            <div class="formating-icons margin-icon" @click="setupLayout">
                                <b-icon icon="columns-gap" font-scale="1.2"></b-icon>
                                <p>Setup Layout</p>
                            </div>
                        </div>
                        <div class="tw-px-4" style="border-right: 1px solid #3e3d3d; max-width: 6rem" v-if="filesave">
                            <div class="formating-icons margin-icon" v-b-toggle.saved_templates @click="handleTemplatesClick">
                                <b-icon icon="inboxes-fill" font-scale="1.2"></b-icon>
                                <p>Templates</p>
                            </div>
                        </div>
                        <!-- <div
              class="tw-px-4"
              style="border-right: 1px solid #3e3d3d; max-width: 6rem"
            >
              <div class="formating-icons margin-icon" @click="openIcons">
                <b-icon icon="app-indicator" font-scale="1.2"></b-icon>
                <p>Replace Icons</p>
              </div>
            </div>
            <div
              class="tw-px-4"
              style="border-right: 1px solid #3e3d3d; max-width: 6rem"
            >
              <div class="formating-icons margin-icon" @click="openIcons">
                <b-icon icon="app-indicator" font-scale="1.2"></b-icon>
                <p>Replace Social Icons</p>
              </div>
            </div> -->

                        <!-- <div class="tw-px-4" style="border-right: 1px solid #3e3d3d">
              <div class="formating-icons margin-icon" @click="toggleShapes">
                <b-icon icon="inboxes-fill" font-scale="1.2"></b-icon>
                <p>Shapes</p>
              </div>
            </div> -->
                    </div>
                </li>
                <li id="table--width--slider" ref="table--width--slider" style="align-items: center; display: none">
                    <div class="table-width--slider">
                        <input @change="changeTableWidth" type="range" id="vol" name="vol" min="200" max="602" />
                    </div>
                </li>
            </ul>
        </section>
        <!-- used to editor container here -->
        <div id="contextMenuContainer">
            <ul>
                <li v-on:click="clickContextItem" value="delete_row">
                    Delete Row
                </li>
                <li>
                    Insert Row
                    <ul>
                        <li v-on:click="clickContextItemChild" value="insert_row_above">
                            Insert row above
                        </li>
                        <li v-on:click="clickContextItemChild" value="insert_row_below">
                            Insert row below
                        </li>
                    </ul>
                </li>
                <li>
                    Insert Cell
                    <ul>
                        <li v-on:click="clickContextItemChild" value="insert_col_above">
                            Insert cell before
                        </li>
                        <li v-on:click="clickContextItemChild" value="insert_col_below">
                            Insert cell after
                        </li>
                    </ul>
                </li>
                <li v-on:click="clickContextItem" value="merge">
                    Merge Cells
                </li>
                <li v-on:click="clickContextItem" value="perperties">
                    Properties
                </li>
                <li v-on:click="clickContextItem" value="delete_table">
                    Delete Table
                </li>
            </ul>
        </div>
        <div id="colorContextMenu">
            <sketch-picker :value="headerFooterBgs['header']" @input="updateHeaderFooterColor($event)" style="margin-left: -4px" />
        </div>
        <b-modal ref="editable-model" hide-footer centered :modal-class="modalClass">
            <template v-slot:modal-title>
                <span v-if="placeHoldeModelData['type'] == 0">Text Placeholder</span>
                <span v-else-if="placeHoldeModelData['type'] == 1">List Placeholder</span>
                <span v-else-if="placeHoldeModelData['type'] == 2">Date Placeholder</span>
                <span v-else>Select Placeholder Template</span>
            </template>

            <div class="placeholder-wrapper row" v-if="placeHoldeModelData.type != 3">
                <div class="col-md-6">
                    <input disabled type="text" v-model="placeHoldeModelData['name']" name="name" class="" placeholder="Placeholder Text" />
                </div>

                <div class="col-md-6" v-if="placeHoldeModelData['type'] != 0">
                    <input type="text" v-model="placeHoldeModelData['default']" name="type" placeholder="Default Value" />
                </div>

                <div class="col-md-12" v-if="placeHoldeModelData['type'] != 0">
                    <textarea v-model="placeHoldeModelData['values']" name="select_option" placeholder="Selection Options"></textarea>
                </div>
            </div>

            <div v-else class="row auto-templates no-padding">
                <div class="col-md-4" v-for="(temp, tindex) in templates" :key="tindex">
                    <div class="template-item" v-bind:class="{selected: selectedTemplate == index}" @click="onSelectTemplate(tindex)">
                        <h4>{{ temp.name }}</h4>
                        <span>Type : {{ temp.type }}</span>
                    </div>
                </div>
            </div>
            <b-button class="mt-3" @click="handleOk" size="sm">Add Placeholder</b-button>
        </b-modal>

        <b-modal ref="hyperlink-model" hide-footer centered :modal-class="modalClass">
            <template v-slot:modal-title>
                <span>Add Hyperlink</span>
            </template>
            <div class="placeholder-wrapper row">
                <div class="col-md-6">
                    <input type="text" v-model="$v.hyperlinkDisplay.$model" name="hyperlinkDisplay" class="" placeholder="Display as" disabled />
                </div>
            </div>
            <div class="tw-text-red-500 tw-mb-2" v-if="$v.hyperlinkDisplay.$error && !$v.hyperlinkDisplay.required">
                Selection of text is required
            </div>
            <div class="placeholder-wrapper row">
                <div :class="$route.name !== 'Editor' ? 'col-md-5' : 'col-md-6'">
                    <input type="text" v-model="$v.hyperlink.$model" name="hyperlink" class="" placeholder="Web Address (URL)" />
                    <div class="tw-text-red-500 tw-mb-2" v-if="$v.hyperlink.$error && !$v.hyperlink.url">
                        Please enter a valid URL
                    </div>
                </div>
                <div class="tw-text-white tw-mt-1" v-if="$route.name !== 'Editor'">
                    OR
                </div>
                <div class="col-md-5" v-if="$route.name !== 'Editor'">
                    <select v-model="hyperlinkPage" class="placeholder-b">
                        <option value="" disabled>
                            Add Anchor Tag
                        </option>
                        <option v-for="row in allPages" :key="row">
                            {{ row }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="tw-text-red-500 tw-mb-2" v-if="$v.hyperlink.$error && !$v.hyperlink.url && errorHyperlink">
                {{ $route.name !== "Editor" ? "Either URL or Hyperlink is required!" : "URL is required" }}
            </div>
            <b-button class="mt-3" @click="addHyperlink" size="sm">Add Hyperlink</b-button>
        </b-modal>

        <b-modal ref="save_template" hide-footer centered :modal-class="modalClass">
            <template v-slot:modal-title>
                <span>Save Template</span>
            </template>

            <div class="placeholder-wrapper row">
                <div class="col-md-12">
                    <input type="text" v-model="$v.templateData.name.$model" name="name" class="" placeholder="Template Name" />
                </div>
                <div class="tw-text-red-500 tw-mb-2 tw-ml-4" v-if="$v.templateData.name.$error && !$v.templateData.name.required">
                    Template Name is required
                </div>
                <div class="col-md-12">
                    <textarea type="text" v-model="templateData['desc']" name="desc" placeholder="Description"></textarea>
                </div>
            </div>

            <b-button class="mt-3" @click="saveTemplate" size="sm">Save Template</b-button>
        </b-modal>
        <b-modal ref="icons_popup" hide-footer centered :modal-class="modalClass">
            <template v-slot:modal-title>
                <div class="header">
                    <div>Choose Icons</div>
                    <div class="icon--options">
                        <input type="checkbox" name="roundIcon" id="" v-model="iconTypeRoundBackground" />round bakcground <input type="checkbox" name="squareIcon" id="" v-model="iconTypeSquareBackground" />square background
                        <input type="color" id="iconBackgroundColor" name="iconBackgroundColor" value="#ff0000" v-model="iconBackgroundColor" />
                    </div>
                </div>
            </template>

            <div class="placeholder-wrapper row">
                <div class="col-md-2" v-for="(icon, iindex) in icons" :key="iindex">
                    <div class="icon-box" @click="addIcon(icon)" v-bind:class="{iconSelect: selectedIcon == icon}">
                        <!-- <b-icon :icon="icon" font-scale="1.3"></b-icon> -->

                        <img width="50" height="50" :key="icon" :src="icon" />
                    </div>
                </div>
            </div>

            <b-button class="mt-3" @click="saveIcon" size="sm">{{ selectedObject === "svg" ? "Change Icon" : "Add Icon" }}</b-button>
        </b-modal>

        <b-modal ref="shapes_popup" hide-footer centered :modal-class="modalClass">
            <template v-slot:modal-title>
                <span>Choose Shape</span>
            </template>

            <div class="placeholder-wrapper row">
                <div class="col-md-2" v-for="(shape, sindex) in shapes_arr" :key="sindex">
                    <div class="shape-box" @click="addIcon(shape)" v-bind:class="{iconSelect: selectedIcon == shape}">
                        <img :src="shape.icon" style="width: 30px; height: 30px" />
                    </div>
                </div>
            </div>

            <b-button class="mt-3" @click="addShape()" size="sm">Add Shape</b-button>
        </b-modal>

        <b-modal ref="social_popup" hide-footer centered>
            <template v-slot:modal-title>
                <span>Choose Icons</span>
            </template>
            <!-- 
        <div class="placeholder-wrapper">
            <b-form-select v-model="selectedSocialIcon" :options="social_icons"
            value-field="icon_data"
            text-field="name"
            ></b-form-select>
        </div> -->
            <div class="placeholder-wrapper row">
                <div class="col-md-2" v-for="(icon, iindex) in social_icons" :key="iindex">
                    <div class="icon-box" @click="addIcon(icon)" v-bind:class="{iconSelect: selectedIcon == icon}">
                        <!-- <b-icon :icon="icon" font-scale="1.3"></b-icon> -->

                        <img width="50" height="50" :key="icon" :src="icon" />
                    </div>
                </div>
            </div>

            <b-button class="mt-3" @click="addSocialIcon" size="sm"> {{ selectedObject == "IMG" ? "Change Social Icon" : "Add Social Icon" }}</b-button>
        </b-modal>

        <b-modal ref="table_properties" hide-footer centered :modal-class="modalClass">
            <template v-slot:modal-title>
                <span>Table Properties</span>
            </template>

            <div class="placeholder-wrapper row">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-12">
                            <label>Border Width</label>
                            <select v-model="tableProperties['border']['type']" name="type" style="margin-bottom: 10px">
                                <option value="dotted">Dotted</option>
                                <option value="dashed">Dashed</option>
                                <option value="solid">Solid</option>
                                <option value="double">Double</option>
                                <option value="groove">Groove</option>
                                <option value="ridge">Ridge</option>
                                <option value="inset">Inset</option>
                                <option value="outset">Outset</option>
                            </select>
                        </div>
                        <div class="col-md-12">
                            <label>Border Width</label>
                            <input type="number" v-model="tableProperties['border']['width']" name="width" placeholder="Border Width" />
                        </div>

                        <div class="col-md-12">
                            <label>Table Width (%)</label>
                            <input type="number" v-model="tableProperties['width']" name="width" placeholder="Border Width" max="100" />
                        </div>
                        <div class="col-md-12">
                            <b-input-group>
                                <b-form-input></b-form-input>

                                <template #append>
                                    <b-dropdown text="Dropdown" variant="success">
                                        <b-dropdown-item>Action A</b-dropdown-item>
                                        <b-dropdown-item>Action B</b-dropdown-item>
                                    </b-dropdown>
                                </template>
                            </b-input-group>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <sketch-picker :value="tableProperties['border']['color']" :preset-colors="['#1a8015', '#edbf2e', '#ff0000']" @input="updateColor($event, 'table_border')" style="margin-left: -4px; margin-top: 3px" />
                </div>
            </div>

            <b-button class="mt-3" @click="saveTableProperties" size="sm">Save Changes</b-button>
        </b-modal>
        <b-modal ref="setup_layout" hide-footer centered :modal-class="modalClass">
            <template v-slot:modal-title>
                <span>Setup Layout</span>
            </template>

            <div class="placeholder-wrapper row">
                <div class="col-md-6" style="border-right: 1px solid rgb(62 61 61)">
                    <p>Margin</p>
                    <div class="row inputs">
                        <div class="col-md-6">
                            Left
                            <input type="number" v-model="layoutMetrix.margins.left" name="mleft" />
                        </div>
                        <div class="col-md-6">
                            Right
                            <input type="number" v-model="layoutMetrix.margins.right" name="mtight" />
                        </div>
                        <div class="col-md-6">
                            Top
                            <input type="number" v-model="layoutMetrix.margins.top" name="mtop" />
                        </div>
                        <div class="col-md-6">
                            Bottom
                            <input type="number" v-model="layoutMetrix.margins.bottom" name="mbottom" />
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <p>Paading</p>
                    <div class="row inputs">
                        <div class="col-md-6">
                            Left
                            <input type="number" v-model="layoutMetrix.paddings.left" name="pleft" />
                        </div>
                        <div class="col-md-6">
                            Right
                            <input type="number" v-model="layoutMetrix.paddings.right" name="pright" />
                        </div>
                        <div class="col-md-6">
                            Top
                            <input type="number" v-model="layoutMetrix.paddings.top" name="ptop" />
                        </div>
                        <div class="col-md-6">
                            Bottom
                            <input type="number" v-model="layoutMetrix.paddings.bottom" name="pbottom" />
                        </div>
                    </div>
                </div>
            </div>

            <b-button class="mt-3" @click="applySetup" size="sm">Apply Changes</b-button>
        </b-modal>
        <div id="dragger_elem">
            <b-icon icon="arrows-move" font-scale="1"></b-icon>
        </div>
    </div>
</template>

<script>
import {Sketch} from "vue-color";
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
import {baseBlackboxUrl} from "../../main";
import domtoimage from "dom-to-image";
import SavedTemplateSidebar from "@/components/saved-template-sidebar";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {required, url} from "vuelidate/lib/validators";
import {DiffDOM, nodeToObj} from "diff-dom";

export default {
    name: "NeoEditorToolBar",
    components: {
        "sketch-picker": Sketch,
        SavedTemplateSidebar,
    },
    props: ["editor", "filesave"],
    computed: {
        filteredItems() {
            return this.savedTemplates.filter((item) => {
                return item.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
            });
        },
        ...mapGetters(["sections"]),
        selectedObject() {
            return this.editor?.selectableObject?.nodeName;
        },
        allPages() {
            let neoPages = [...document.querySelectorAll(".neo-page")];
            return neoPages.length;
        },
    },
    async mounted() {
        this.loadIcons();
        let that = this;
        this.attachUndoRedoEvents();

        // this.gotopage = this.$route.hash.match(/(\d+)/);;
        await that.getSavedTemplates();
        fetch("https://demo7939192.mockable.io/templates")
            .then((response) => response.json())
            .then((value) => {
                this.templates = value.data;
            });

        window.onPlaceholderClick = function(e) {
            let placeHoldeModelData = JSON.parse(e.target.getAttribute("editor-data"));
            let popup = document.createElement("span");
            let inputText = document.createElement(placeHoldeModelData.type == 0 ? "input" : "select");
            let doneBtn = document.createElement("button");
            let cancleBtn = document.createElement("button");
            doneBtn.className = "btn btn-sm btn-info";
            doneBtn.innerText = "Done";
            doneBtn.addEventListener("click", function() {
                popup.previousSibling.innerText = inputText.value;
                e.target.parentNode.removeChild(popup);
            });
            cancleBtn.className = "btn btn-danger btn-sm";
            cancleBtn.innerText = "Close";
            cancleBtn.addEventListener("click", function() {
                e.target.parentNode.removeChild(popup);
            });
            if (placeHoldeModelData.type == 0) {
                inputText.type = "text";
            } else {
                let optiontext = "";
                let selectVal = typeof placeHoldeModelData.values == "object" ? placeHoldeModelData.values : placeHoldeModelData.values.split("\n");
                selectVal.forEach(function(op) {
                    optiontext = optiontext + "<option>" + op + "</option>";
                });
                inputText.innerHTML = optiontext;
            }
            inputText.value = e.target.textContent;
            popup.classList.add("editor_inline");
            popup.appendChild(inputText);
            popup.appendChild(doneBtn);
            popup.appendChild(cancleBtn);
            e.target.parentNode.appendChild(popup);
        };
    },
    data() {
        return {
            elementA: "",
            elementB: "",
            undoStates: [],
            redoStates: [],
            ctrlDown: false,
            shiftDown: false,
            zDown: false,
            updateDOM: null,
            //  editor: null,
            iconBackgroundColor: "#fff",
            iconTypeRoundBackground: false,
            iconTypeSquareBackground: false,
            raw_html: "",
            modalClass: ["editor-modal"],
            shapes_arr: [
                {
                    name: "Rectangle",
                    icon: require("@/assets/rect.svg"),
                },
                {
                    name: "Star",
                    icon: require("@/assets/star.svg"),
                },
                {
                    name: "Left Arrow",
                    icon: require("@/assets/left-arrow.svg"),
                },
            ],
            search: "",
            headerFooterBgs: {
                header: "#FFFFFF",
                footer: "#FFFFFF",
            },
            bold: false,
            weights: ["100", "200", "300", "400", "500", "600", "700", "800", "900"],
            activeFontWeight: 100,
            fontSize: 12,
            textCase: "lowercase",
            italic: false,
            underline: false,
            strikethrough: false,
            selectedIcon: "",
            zIndexes: 100,
            icons_list: ["cancel.svg", "check.svg", "check-mark.svg", "flag.svg", "x-mark.svg", "letter-x.svg", "checked.svg"],
            social_icons_list: ["facebook.svg", "flickr.svg", "twitter.svg", "linkedin.svg", "stackoverflow.svg", "skype.svg", "instagram.svg", "youtube.svg", "website.svg", "gitlab.svg", "github.svg", "pinterest.svg", "medium.svg", "angellist.svg", "f6s.svg", "google.svg", "add.svg"],
            social_icons: [],
            icons: [],
            //   icons_list:["flag","flag-fill","x","x-circle","x-circle-fill","x-octagon","x-octagon-fill","x-square","x-square-fill","check2","check2-all","check2-circle","check2-square","check-circle","check-circle-fill","check-square","check-square-fill"],
            tRow: 0,
            tCol: 0,
            superv: false,
            sub: false,
            isMouseDown: false,
            selectedRow: null,
            selectedCol: null,
            prevCursorSelection: null,
            j_left: false,
            j_right: false,
            j_center: false,
            j_all: false,
            textColor: "#000",
            bgColor: "#FFF",
            value1: "sdfsd",
            tableDragColumn: -1,
            tableDragRow: -1,
            placeHoldeModelData: {},
            popover_target: "popover",
            templates: [],
            savedTemplates: [],
            savedTemplateIndex: -1,
            selectedTemplate: -1,
            selectedElement: null,
            templateEditMode: false,
            //   social_icons : [{
            //       icon_data: null, name: 'Please select social icon' ,
            //   }],
            activeSelection: "",
            selectedSocialIcon: null,
            templateData: {
                name: "",
                desc: "",
            },
            tableSelection: {
                start: [],
                end: [],
            },
            BASE_URL: baseBlackboxUrl,
            isShift: false,
            isHeader: "1",
            draggerStart: false,
            tableProperties: {
                width: 100,
                border: {
                    width: 1,
                    color: "#dee2e6",
                    type: "solid",
                },
            },
            margins: {
                left: 60,
                right: 60,
                top: 80,
            },
            layoutMetrix: {
                margins: {
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                },
                paddings: {
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                },
            },
            pages: [
                {
                    id: "page_1",
                    elem: [
                        {
                            id: Date.now(),
                            type: "div",
                            text: "",
                            height: 0,
                        },
                    ],
                },
            ],
            hyperlink: "",
            hyperlinkDisplay: "",
            gotopage: "",
            hyperlinkPage: "",
            errorHyperlink: false,
        };
    },
    validations: {
        hyperlink: {
            url,
        },
        hyperlinkDisplay: {
            required,
        },
        templateData: {
            name: {
                required,
            },
        },
    },
    methods: {
        ...mapActions(["fetchAllSections"]),
        ...mapMutations(["SET_SECTIONS"]),
        keyUpEvent(e) {
            if (e.keyCode === 17 || e.keyCode === 91) this.ctrlDown = false;
            if (e.keyCode === 16) this.shiftDown = false;
            if (e.keyCode === 90) {
                if (this.shiftDown && this.ctrlDown && this.redoStates.length) {
                    this.redo();
                } else if (this.ctrlDown && this.undoStates.length) {
                    this.undo();
                }
                this.zDown = false;
            }
        },
        keyDownEvent(e) {
            if (e.keyCode === 17 || e.keyCode === 91) this.ctrlDown = true;
            if (e.keyCode === 16) this.shiftDown = true;
            if (e.keyCode === 90) {
                this.zDown = true;
                if (this.shiftDown && this.ctrlDown) {
                    e.preventDefault();
                    e.stopPropagation();
                    e.cancelBubble = true;
                } else if (this.ctrlDown) {
                    e.preventDefault();
                    e.stopPropagation();
                    e.cancelBubble = true;
                }
            }
        },
        updateDomDiff() {
            clearInterval(this.updateDOM);
            const dd = new DiffDOM();
            this.elementB = nodeToObj(document.getElementById("editor"));
            let diff = dd.diff(this.elementA, this.elementB);
            if (diff && diff.length) {
                this.undoStates.push(diff);
                this.elementA = nodeToObj(document.getElementById("editor"));
            }
            this.updateDOM = setInterval(this.updateDomDiff, 2000);
        },
        undo() {
            let element = this.undoStates.pop();
            const dd = new DiffDOM();
            dd.undo(document.getElementById("editor"), element);
            this.redoStates.push(element);
            this.elementA = nodeToObj(document.getElementById("editor"));
        },
        redo() {
            let element = this.redoStates.pop();
            const dd = new DiffDOM();
            dd.undo(document.getElementById("editor"), element);
            this.undoStates.push(element);
            this.elementA = nodeToObj(document.getElementById("editor"));
        },
        attachUndoRedoEvents() {
            let that = this;
            setTimeout(() => {
                if (this.$route.name === "Editor") {
                    document.getElementById("editor").onkeydown = function(e) {
                        that.keyDownEvent(e);
                    };
                    document.getElementById("editor").onkeyup = function(e) {
                        that.keyUpEvent(e);
                    };

                    this.updateDOM = setInterval(() => {
                        this.elementA = nodeToObj(document.getElementById("editor"));
                        this.updateDomDiff();
                    }, 2000);
                }
                if (this.editor) this.editor.init();
            }, 2000);
        },
        changeTableWidth(e) {
            this.editor.changeTableWidth(e);
        },
        loadIcons() {
            this.icons_list.forEach((icon) => {
                var ico = require(`@/assets/icons/${icon}`);
                this.icons.push(ico);
            });
            this.social_icons_list.forEach((icon) => {
                var ico = require(`@/assets/icons/social-medias/${icon}`);
                this.social_icons.push(ico);
            });
        },
        selectTemplate: function(index) {
            // If  a template is selected , we are in edit mode.

            this.templateEditMode = true;

            this.savedTemplateIndex = index;
            let parent = document.getElementById("editor").parentNode;
            var selectedTemplate = this.savedTemplates.find((template) => template.id === index);
            parent.innerHTML = selectedTemplate.html;
            this.attachUndoRedoEvents();

            this.templateData.name = selectedTemplate.name;
            this.templateData.desc = selectedTemplate.desc;
            // Reattach events??..
            this.editor.reattachEvents({headersFootersAllowEdit: true});
        },
        createNewPage: function() {
            this.$router.go();
        },
        toggleFullscreen: function() {
            if (!document.fullscreenElement) {
                document.documentElement.requestFullscreen();
            } else {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                }
            }
        },
        changeFontWeight: function() {
            //this.editor.setTextStyle({type:'font-weight', value:this.activeFontWeight});
            this.editor.setTextProperty("font-weight", this.activeFontWeight);
        },
        addIcon: function(icon) {
            this.selectedIcon = icon;
        },
        toggleShapes: function() {
            this.$refs["shapes_popup"].show();
        },
        hideShapes: function() {
            this.$refs["shapes_popup"].hide();
        },
        toggleMargins: function() {
            this.editor.toggleMargins();
        },
        clickContextItem: function(e) {
            let clicked = e.target.getAttribute("value");
            if (clicked == "perperties") {
                this.$refs["table_properties"].show();
                this.editor.closeContextMenu();
            } else {
                this.editor.clickContextItem(e, clicked);
            }
        },
        clickContextItemChild: function(e) {
            this.editor.clickContextItemChild(e);
        },
        setupLayout: function() {
            this.layoutMetrix = this.editor.getLayoutMetrix();
            this.$refs["setup_layout"].show();
        },
        applySetup: function() {
            this.editor.setLayoutMetrix(this.layoutMetrix);
            this.$refs["setup_layout"].hide();
        },
        onSelectTemplate: function(index) {
            this.selectedTemplate = index;
            this.$forceUpdate();
        },

        opanSaveModal: function() {
            // if(this.savedTemplateIndex!=-1){
            const selectedTemplate = this.savedTemplates?.find((template) => template.id === this.savedTemplateIndex);
            this.templateData["name"] = selectedTemplate?.name ? selectedTemplate?.name : this.templateData["name"];
            this.templateData["desc"] = selectedTemplate?.desc ? selectedTemplate?.desc : this.templateData["desc"];
            // }
            if (selectedTemplate) {
                this.templateEditMode = true;
                this.$refs["save_template"].show();
            } else {
                this.$toast.error("Select a template or Save as new the current template");
            }
        },
        openSaveAsModal: function() {
            this.templateData["name"] = "";
            this.templateData["desc"] = "";
            this.templateEditMode = false;
            this.$refs["save_template"].show();
        },
        openIcons: function() {
            this.$refs["icons_popup"].show();
        },
        openSocialList: function() {
            this.$refs["social_popup"].show();
        },
        removeStyle: function() {
            this.editor.resetElemStyle();
        },
        imageOver: function() {
            this.editor.overlayElem();
        },
        saveIcon: function() {
            // let icon = this.selectedIcon;
            // let iconType = this.iconType
            var iconType = null;
            if (this.iconTypeSquareBackground) iconType = "squareIcon";
            if (this.iconTypeRoundBackground) iconType = "roundIcon";
            if (this.selectedObject === "svg") {
                this.editor.changeIcon(this.editor?.selectableObject, this.selectedIcon, iconType, this.iconBackgroundColor);
            } else {
                this.editor.addIcon(this.selectedIcon, iconType, this.iconBackgroundColor, true);
            }
            // let range =  this.editor.getPrevCursor();
            // let html = '';
            // const el = document.createElement('SPAN')
            // const  vm = new Vue({
            //     template: '<div><b-icon icon="'+icon+'" font-scale="2"></b-icon><br></div>',
            // })
            // vm.$mount(el)
            // vm.$nextTick(() => {
            //     html = vm.$el;
            //     range.insertNode(html);
            // })
            this.$refs["icons_popup"].hide();
        },
        addSocialIcon: function() {
            let icon = this.selectedIcon;
            if (this.selectedObject === "IMG") {
                this.editor.selectableObject.src = icon;
            } else {
                this.editor.addIcon(icon, null, null, false); //svg transform false
            }
            // let range =  this.prevCursorSelection;
            // let imgWrap = document.createElement('div');
            // let img = document.createElement('img');
            // img.src = this.selectedSocialIcon;
            // img.setAttribute("draggable", "false")
            // imgWrap.style.height = "auto";
            // imgWrap.style.width = "60px";
            // imgWrap.classList.add("resizable")
            // imgWrap.setAttribute("contenteditable", false);
            // imgWrap.appendChild(img)
            // range.insertNode(imgWrap);
            this.$refs["social_popup"].hide();
        },
        saveTemplate: async function() {
            this.$v.$touch();
            if (!this.$v.templateData.name.$error) {
                let loader = this.$loading.show({canCancel: true});
                let wrapper = document.getElementById("editor").parentNode;

                let node = document.querySelector(".neo-page");
                const thumbnail = await domtoimage.toPng(node);
                let template = {
                    name: this.templateData["name"],
                    desc: this.templateData["desc"],
                    html: wrapper.innerHTML,
                    thumbnail,
                };
                this.$v.$reset();
                this.templateEditMode ? await this.onUpdateTemplate(template) : await this.onSavedTemplate(template);

                loader.hide();
            }
        },

        handleOk: function(modal) {
            modal.preventDefault();
            let data = this.placeHoldeModelData["type"] == 3 ? this.templates[this.selectedTemplate] : this.placeHoldeModelData;
            // this.editor.addListPlaceholder(data)
            // let range =  this.prevCursorSelection
            var range = this.editor.activeEditorSelection;
            // if (!activeNode.closest("TD")) {
            //   // activeNode.childNode s[0]?.remove();
            // }
            // let range = this.editor.getRange();
            let tag = document.createElement("span");
            let child = document.createElement("span");
            child.setAttribute("onclick", "onPlaceholderClick(event)");
            tag.setAttribute("contenteditable", false);
            child.setAttribute("editor-data", JSON.stringify(data));
            child.classList.add("editable-text");
            // child.innerHTML =
            //   !data.default || data.default == "" ? data.name : data.default;
            child.innerHTML = this.placeHoldeModelData.name;
            tag.appendChild(child);
            tag.classList.add("editor_parent");
            // activeNode.appendChild(tag);
            range.deleteContents();
            range.insertNode(tag);

            // activeNode.innerHTML = activeNode.innerHTML.replace(
            //     this.activeSelection,
            //     tag.outerHTML
            //   );

            this.$refs["editable-model"].hide();
        },
        updateColor: function(e, type) {
            this.editor.changeColor(e.hex, type);
            if (type == "table_border") {
                this.tableProperties["border"]["color"] = e.hex;
            }
            // if(type=="text"){
            //     this.textColor = e.hex;
            //     this.toggleCmd(e.hex, {style : [{key : "color", value : e.hex}]})
            // }else if(type == "bg"){
            //     this.bgColor = e.hex;
            //     this.toggleCmd(e.hex, {style : [{key : "background", value : e.hex}]})
            // }else if(type == "table_border"){
            //     this.tableProperties['border']['color'] = e.hex;
            // }
        },
        updateHeaderFooterColor: function(e, type) {
            if (document.getElementsByClassName("activeHeader").length > 0) {
                document.getElementsByClassName("activeHeader")[0].style.background = e.hex;
            } else {
                document.getElementsByClassName("activeFooter")[0].style.background = e.hex;
            }
        },
        saveTableProperties: function() {
            let properties = this.tableProperties;
            let activeTable = document.querySelector("[table-selected='true']");
            let colorTxt = properties.border.width + "px " + properties.border.type + " " + properties.border.color;
            // activeTable.style.width = properties.width + "%"
            activeTable?.style?.setProperty("border", colorTxt, "important");
            activeTable?.rows.forEach(function(row) {
                row?.cells?.forEach(function(column) {
                    column?.style?.setProperty("border", colorTxt, "important");
                });
            });
            this.$refs["table_properties"].hide();
        },
        fitImg: function() {
            this.editor.fitImg();
        },
        rotateText: function() {
            this.editor.rotateText();
            // let range = this.editor.getRange();
            // if (range.collapsed) return;
            // let elem = range.commonAncestorContainer.parentNode;
            // elem.innerHTML = `<span>${elem.innerHTML}</span>`;
            // if (elem.cellIndex) {
            //   elem.innerHTML = "<span>" + elem.innerHTML + "</span>";
            //   let cellIndex = elem.cellIndex;
            //   let oldWidth = elem.offsetParent.rows[0].cells[cellIndex].style.width;
            //   oldWidth = parseFloat(oldWidth.split("%")[0]);
            //   let nextColumnWidth = 0;
            //   if (cellIndex + 1 < elem.offsetParent.rows[0].cells.length) {
            //     nextColumnWidth =
            //       elem.offsetParent.rows[0].cells[cellIndex + 1].style.width;
            //     nextColumnWidth = parseFloat(nextColumnWidth.split("%")[0]);
            //   }
            //   elem.offsetParent.rows[0].style.height =
            //     elem.offsetParent.rows[0].cells[cellIndex].offsetWidth + "px";
            //   elem.offsetParent.rows.forEach(function (row) {
            //     row.cells[cellIndex].style.width = "10%";
            //     if (cellIndex + 1 < elem.offsetParent.rows[0].cells.length) {
            //       row.cells[cellIndex + 1].style.width =
            //         nextColumnWidth + (oldWidth - 10) + "%";
            //     }
            //   });
            // }
            // elem.children[0].style.transform = `rotate(180deg)`;
            // elem.children[0].style.writingMode = "vertical-rl";
        },

        onMouse: function(row, col, event) {
            event.preventDefault();
            let table = document.getElementById("edit_table");
            for (let i = 0; i < table.children.length; i++) {
                for (let j = 0; j < table.children[i].children.length; j++) {
                    if (i < row) {
                        if (j < col) {
                            table.children[i].children[j].style.background = "rgb(143 211 243)";
                            table.children[i].children[j].style.border = "1px solid rgb(23 135 187)";
                        } else {
                            table.children[i].children[j].style.background = "#252323";
                            table.children[i].children[j].style.border = "1px solid #CCC";
                        }
                    } else {
                        table.children[i].children[j].style.background = "#252323";
                        table.children[i].children[j].style.border = "1px solid #CCC";
                    }
                }
            }
            this.tRow = row;
            this.tCol = col;
        },
        justifyContent: function(type) {
            // this.toggleCmd(type, {style : [{key : "text-align", value : type}, {key : "display" , value : "block"}]})
            this.editor.textAlign(type);
        },
        toggleModel: function(type) {
            // if(type) {
            // this.placeHoldeModelData['type'] = type;
            // this.$forceUpdate()
            // this.$refs['editable-model'].toggle();
            // } else {
            //     this.editor.addPlaceholder();

            // }

            var activeSelection = window.getSelection().toString();
            this.activeSelection = activeSelection;
            this.placeHoldeModelData.name = this.activeSelection;

            this.placeHoldeModelData["type"] = type;
            this.$forceUpdate();
            this.$refs["editable-model"].toggle();
        },

        toggleCmd: function(type, args) {
            args = args ? args : {};
            switch (type) {
                case "bold":
                    // args = {style : [{key : "font-weight", value : 'bold'}]};
                    this.editor.applyTextDecoration("bold");
                    return;
                    // this.editor.setTextProperty('font-weight', 'bold')
                    // return;
                    break;
                case "italic":
                    // args = {style : [{key : "font-style", value : 'italic'}]};
                    this.editor.applyTextDecoration("italic");
                    return;
                    break;
                case "underline":
                    // args = {style : [{key : "text-decoration", value : 'underline'}]};
                    this.editor.applyTextDecoration("underline");
                    return;
                    break;
                case "strikethrough":
                    // args = {style : [{key : "text-decoration", value : 'line-through'}]};
                    this.editor.applyTextDecoration("strikethrough");
                    return;
                    break;
                case "super":
                    this.editor.applyTextDecoration("superscript");
                    return;
                    // args = {style : [{key : 'vertical-align', value : 'super'}, {key : 'font-size', value : '60%'}]}
                    break;
                case "sub":
                    this.editor.applyTextDecoration("subscript");
                    return;
                    // args = {style : [{key : 'vertical-align', value : 'sub'}, {key : 'font-size', value : '60%'}]}
                    break;
                case "createLink":
                    this.hyperlinkDisplay = window.getSelection().toString();

                    this.$refs["hyperlink-model"].toggle();
                    // this.editor.applyTextDecoration("createLink");
                    return;
                // args = {style : [{key : 'vertical-align', value : 'sub'}, {key : 'font-size', value : '60%'}]}
                // break;
                case "foreColor":
                    this.editor.applyTextDecoration("foreColor");
                    return;
                    // args = {style : [{key : 'vertical-align', value : 'sub'}, {key : 'font-size', value : '60%'}]}
                    break;
                case "bgColor":
                    this.editor.applyTextDecoration("backColor");
                    return;
                    // args = {style : [{key : 'vertical-align', value : 'sub'}, {key : 'font-size', value : '60%'}]}
                    break;
            }
            return this.editor.toggleCmd(type, args);
        },
        openTablePicker: function() {},
        addList: function(type, listType) {
            this.editor.addList(type, listType);
        },

        fetchImageUrl(imgId) {
            let url = `${process.env.VUE_APP_IMAGES_URL}/${imgId}`;
            // fetch(url, {
            //     method: 'GET',
            //     headers: {
            //         "Authorization": "Basic bmVvdXNlcjpub3RhZGVtb0AxMjM="
            //     }
            // })
            // .then(response => response.json())
            // .then(data => {
            let range = this.prevCursorSelection;
            let imgWrap = document.createElement("div");
            let img = document.createElement("img");
            img.src = url;
            img.setAttribute("draggable", "false");
            imgWrap.style.height = "auto";
            imgWrap.style.width = "200px";
            imgWrap.style.marginRight = "15px";
            imgWrap.classList.add("resizable");
            imgWrap.setAttribute("contenteditable", false);
            imgWrap.appendChild(img);
            range.insertNode(imgWrap);

            // })
            // .catch((error) => {
            // });
        },
        addShape: function() {
            let icon = this.selectedIcon.icon;
            this.editor.addIcon(icon, true);
            this.hideShapes();
            // let range =  this.prevCursorSelection
            // let imgWrap = document.createElement('div');
            // let img = document.createElement('img');
            // img.src = this.selectedIcon.icon;
            // img.setAttribute("draggable", "false")
            // imgWrap.style.height = "auto";
            // imgWrap.style.width = "200px";
            // imgWrap.style.marginRight = "15px";
            // imgWrap.classList.add("resizable")
            // imgWrap.setAttribute("contenteditable", false);
            // imgWrap.appendChild(img);
            // range.insertNode(imgWrap);
            // this.hideShapes();
        },
        addImage: function() {
            let files = document.getElementById("img_file").files;
            var that = this;
            if (files.length) {
                let url = this.BASE_URL + "/api/v1/uploads/reports/images";
                var data = new FormData();
                data.append("file", files[0]);
                fetch(url, {
                    method: "POST",
                    headers: {
                        Authorization: "Basic bmVvdXNlcjpub3RhZGVtb0AxMjM=",
                    },
                    body: data,
                })
                    .then((response) => response.json())
                    .then((data) => {
                        let fileId = data.file;
                        // let url = this.BASE_URL+'/images/'+fileId;
                        // this.fetchImageUrl(fileId);
                        let url = `${process.env.VUE_APP_IMAGES_URL}/${fileId}`;
                        that.editor.addImage(url);
                    })
                    .catch((error) => {});
            } else {
                let urlElem = document.getElementById("image_url");
                this.editor.addImage(urlElem.value);
            }
        },
        addTable: function() {
            this.editor.addTable(this.tRow, this.tCol);
        },
        onTextTransformChange(e) {
            let val = e.target.value;
            // this.toggleCmd(val, {style : [{key : "text-transform", value : val}]});
            this.editor.textTransformCase(val);
        },
        onTextSizeChange(e) {
            let val = e.target.value;
            // this.toggleCmd(val, {style : [{key : "font-size", value : val}]});
            this.editor.changeSize(val);
        },
        resetButtons: function() {
            this.bold = false;
            this.italic = false;
            this.underline = false;
            this.strikethrough = false;
            this.superv = false;
            this.sub = false;
            this.j_all = false;
            this.j_center = false;
            this.j_right = false;
            this.j_left = false;
        },
        onSavedTemplate: function(template) {
            // this.savedTemplates = [];
            let url = this.BASE_URL + "/api/v1/reports";

            fetch(url, {
                method: "POST",
                headers: {
                    Authorization: "Basic bmVvdXNlcjpub3RhZGVtb0AxMjM=",
                },
                body: JSON.stringify(template),
            })
                .then((response) => response.json())
                .then((data) => {
                    // this.getSavedTemplates();
                    this.savedTemplateIndex = data.id;
                    template.id = data.id;
                    this.savedTemplates.push(template);
                    this.SET_SECTIONS(this.savedTemplates);
                })
                .catch((error) => {});

            this.$refs["save_template"].hide();

            // var cvx = null;
            // html2canvas(document.querySelector('.neo-page')).then(function(canvas) {
            //     // document.body.appendChild(canvas);
            //     cvx = canvas
            //     var img = cvx.toDataURL("image/png").replace("image/png", "image/octet-stream")

            //     template.thumbnail = img

            // fetch(url, {
            //     method: 'POST',
            //     headers: {
            //         "Authorization": "Basic bmVvdXNlcjpub3RhZGVtb0AxMjM="
            //     },
            //     body : JSON.stringify(template)
            // })
            // .then(response => response.json())
            // .then(data => {
            //     that.getSavedTemplates();
            // })
            // .catch((error) => {
            // });

            // that.$refs['save_template'].hide();

            // });
        },
        onUpdateTemplate: function(template) {
            // this.savedTemplates = [];
            let url = this.BASE_URL + "/api/v1/reports/" + this.savedTemplateIndex;
            fetch(url, {
                method: "POST",
                headers: {
                    Authorization: "Basic bmVvdXNlcjpub3RhZGVtb0AxMjM=",
                },
                body: JSON.stringify(template),
            })
                .then((response) => response.json())
                .then((data) => {
                    let index = this.savedTemplates.findIndex((n) => n.id == this.savedTemplateIndex);
                    template.id = this.savedTemplateIndex;
                    this.savedTemplates.splice(index, 1, template);
                    this.SET_SECTIONS(this.savedTemplates);
                    // this.getSavedTemplates();
                })
                .catch((error) => {});
            this.$refs["save_template"].hide();
        },
        getSavedTemplates: async function() {
            await this.fetchAllSections();
            this.savedTemplates = this.sections;
        },
        getSocialIcons: function() {
            let url = this.BASE_URL + "/api/v1/social_platforms";
            let that = this;
            fetch(url, {
                method: "GET",
                headers: {
                    Authorization: "Basic bmVvdXNlcjpub3RhZGVtb0AxMjM=",
                },
            })
                .then((response) => response.json())
                .then((data) => {
                    that.social_icons = [...that.social_icons, ...data];
                })
                .catch((error) => {});
        },
        deleteTemplate: function(id) {
            let url = this.BASE_URL + "/api/v1/reports/" + id;
            fetch(url, {
                method: "DELETE",
                headers: {
                    Authorization: "Basic bmVvdXNlcjpub3RhZGVtb0AxMjM=",
                },
            })
                .then((response) => response.json())
                .then((data) => {})
                .catch((error) => {});
        },
        focusCursor: function(pageId, focusElem) {
            let page = document.getElementById(pageId);
            let content = page.getElementById("editor");
            var range = document.createRange();
            var selection = window.getSelection ? window.getSelection() : document.selection.createRange().text;
            range.setStart(focusElem ? focusElem : content.children[0], 1);
            range.collapse(true);
            selection.removeAllRanges();
            selection.addRange(range);
            this.prevCursorSelection = range;
        },
        resetElem: function() {
            this.resetFooterAndHeader();
        },
        keyPressHandler: function(event) {
            let pageId = event.path.find((e) => {
                return e.id && e.id.includes("page");
            }).id;
            let pageIndex = this.pages.findIndex((page) => {
                return page.id == pageId;
            });
            let el = null;
            if (this.prevCursorSelection.commonAncestorContainer.nodeType == 3) {
                el = this.prevCursorSelection.commonAncestorContainer.parentNode;
            } else if (this.prevCursorSelection.commonAncestorContainer.tagName == "LI") {
                el = this.prevCursorSelection.commonAncestorContainer.parentNode.parentNode;
            } else {
                el = this.prevCursorSelection.commonAncestorContainer;
            }
            let elemIndex = Array.from(event.target.children).indexOf(el);
            let that = this;
            switch (event.keyCode) {
                case 9:
                    if (el.tagName == "TD") {
                        let rowLength = el.offsetParent.rows.length;
                        let colLength = el.offsetParent.rows[rowLength - 1].cells.length;
                        if (rowLength == el.parentNode.rowIndex + 1 && colLength == el.cellIndex + 1) {
                            event.preventDefault();
                            that.addTableRow("insert_row_below");
                        }
                        return;
                    }
                    break;
                case 13:
                    if ((el.tagName == "LI" && el.textContent != "") || that.isShift) {
                        return;
                    }
                    event.preventDefault();
                    var currentIndex = this.getCalculatedPageBreakIndex(pageId, pageIndex, elemIndex);
                    var elemObj = {
                        id: Date.now(),
                        type: "div",
                        text: "",
                        height: el.offsetHeight,
                    };
                    this.pages[currentIndex].elem.splice(elemIndex + 1, 0, elemObj);
                    setTimeout(function() {
                        var pageElem = document.getElementsByClassName("page")[currentIndex];
                        var content = pageElem.getElementsByClassName("content_wrap")[0];
                        that.focusCursor(pageId, content.children[currentIndex == pageIndex ? elemIndex + 1 : 0]);
                    });
                    break;
                case 8:
                    if (el.textContent == "" && elemIndex > 0) {
                        that.pages[pageIndex].elem.splice(elemIndex, 1);
                        let prevElem = event.target.children[elemIndex - 1];
                        that.focusCursor(pageId, prevElem);
                        if (pageIndex + 1 < that.pages.length) {
                            that.pages[pageIndex]["elem"].push(that.pages[pageIndex + 1]["elem"][0]);
                            setTimeout(function() {
                                let nextDom = document.getElementsByClassName("page")[pageIndex + 1].children[1].children[0].innerHTML;
                                let mydom = event.target.children[event.target.children.length - 1];
                                mydom.innerHTML = nextDom;
                                that.pages[pageIndex + 1]["elem"].splice(0, 1);
                            });
                        }
                    } else if (pageIndex == 0 && elemIndex == 0 && el.textContent == "") {
                        event.preventDefault();
                    }

                    break;
                default:
                    if (event.key.length > 1 || el.tagName == "LI") return;
                    this.pages[pageIndex].elem[elemIndex].text = this.pages[pageIndex].elem[elemIndex].text + event.key;
                    this.pages[pageIndex].elem[elemIndex]["height"] = el.offsetHeight;
                    break;
            }
        },
        headerActive: function(event) {
            event.preventDefault();
            event.stopPropagation();
            if (event.target.getAttribute("contenteditable") == "false") {
                event.target.setAttribute("contenteditable", "true");
                event.target.className = "header_content header_active";
            }
        },
        footerActive: function(event) {
            event.preventDefault();
            if (event.target.getAttribute("contenteditable") == "false") {
                event.target.setAttribute("contenteditable", "true");
                event.target.className = "footer_content footer_active";
            }
        },
        resetFooterAndHeader: function() {
            document.getElementsByClassName("header_content").forEach(function(header) {
                header.className = "header_content";
                header.setAttribute("contenteditable", "false");
            });
            document.getElementsByClassName("footer_content").forEach(function(footer) {
                footer.className = "footer_content";
                footer.setAttribute("contenteditable", "false");
            });
        },
        getCalculatedPageBreakIndex: function(pageId, pageIndex, elemIndex) {
            let height = 0;
            let that = this;
            let currentPageIndex = pageIndex;
            this.pages[pageIndex].elem.forEach(function(el) {
                height = height + el.height;
                if (height > 1032) {
                    currentPageIndex = pageIndex + 1;
                    if (pageIndex + 1 == that.pages.length) {
                        let len = that.pages.length + 1;
                        let page = {
                            id: "page_" + len,
                            elem: [],
                        };
                        that.pages.push(page);
                        setTimeout(function() {
                            let page = document.getElementsByClassName("page")[pageIndex + 1];
                            let header = page.getElementsByClassName("header_content")[0];
                            header.innerHTML = document.getElementsByClassName("page")[pageIndex].getElementsByClassName("header_content")[0].innerHTML;
                        });
                    }
                }
            });
            return currentPageIndex;
        },
        handleImageResize() {
            let pageId = this.editor.getActivePageId(this.selectedElement);
            if (this.editor.checkNeedsPageBreak(pageId)) {
                this.shiftContentToNextPages(pageId);
            }
        },
        handleChangeFontFamily({target}) {
            const {value} = target;
            this.editor.changeFontFamily(value);
        },
        async handleTemplatesClick() {
            this.$modal.hide("delete-modal");
            // await this.getSavedTemplates();
        },
        addHyperlink() {
            this.$v.$touch();
            if (!this.$v.$error) {
                if (this.hyperlink || this.hyperlinkPage) {
                    this.editor.addHyperlinkTag(this.hyperlinkDisplay, this.hyperlink, this.hyperlinkPage);
                    this.$refs["hyperlink-model"].toggle();
                    this.$v.$reset();
                    this.hyperlinkDisplay = "";
                    this.hyperlink = "";
                    this.hyperlinkPage = "";
                    this.errorHyperlink = false;
                } else {
                    this.errorHyperlink = true;
                }
            }
        },
        handleGoToPage() {
            var element = document.getElementById(`page--${this.gotopage}`);
            element.scrollIntoView({
                block: "start",
                behavior: "smooth",
            });
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    @import "~bootstrap/scss/bootstrap.scss";
    @import "~bootstrap-vue/src/index.scss";
}

.header {
    display: flex;
    /* width: 100%; */
    justify-content: center;
    justify-content: space-around;
}
.icon--options {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 50px;
    input {
        margin-left: 10px;
        margin-right: 8px;
    }
}

.content-section {
    // margin-top: 179px  !important;
    padding: 10px;
    background: rgb(248, 249, 250);
}

#main-wrapper * {
    -webkit-print-color-adjust: exact !important;
    transition: 0.3s all;
}
.fileBtn {
    color: #fff;
    font-size: 14px;
    position: relative;
    top: 2px;
    font-weight: bold;
    transform: translate3d(-5px, 27px, 0px);
}
.b-sidebar-outer {
    z-index: 10000000;
}
.search {
    width: 90%;
    height: 35px;
    padding-left: 15px;
    padding-right: 15px;
    outline: none;
    border: 1px solid #ccc;
    border-radius: 50px;
    margin-left: 15px;
}
#main-wrapper {
    position: relative;
}
.disableSelection {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    outline: 0;
}
.toolbar {
    background-color: #2c3752;
    position: fixed;
    z-index: 1000;
    width: 100%;
    top: 0;
    left: 0;
}
.toolbar .toolbar_actions {
    padding: 10px;
    display: inline-flex;
}
.toolbar .toolbar_actions li {
    color: #000;
    font-style: bold;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    text-align: center;
    margin-right: 5px;
    font-size: 11px;
    list-style-type: none;
}
.toolbar .toolbar_actions li.red {
    background-color: rgb(255, 94, 0);
}
.toolbar .toolbar_actions li.base {
    background-color: rgb(220, 187, 4);
}
.toolbar .toolbar_actions li.green {
    background-color: rgb(60, 175, 1);
}
.toolbar .toolbar_actions li svg {
    opacity: 0;
}
.toolbar .toolbar_actions:hover svg {
    opacity: 1;
}
.toolbox {
    background-color: var(--brand-accent-dark);
    margin-top: 35px;
    padding: 10px;
    border-bottom: 2px solid #151414;
    position: fixed;
    z-index: 999;
    width: 100%;
    top: 0;
    left: 0;
}
.page {
    background-color: #fff;
    width: 21cm;
    margin: 0 auto;
    margin-bottom: 0.5cm;
    display: flex;
    flex-direction: column;
}
#editor {
    width: 794px;
    background: rgb(248, 249, 250);
}
.content_wrap {
    outline: none;
    min-height: 100px;
    flex: 1;
}
/* .content_wrap p{
    padding: 5px 0px;
    margin : 0px 60px;
    margin-bottom: 0px;
} */
.content-p {
    padding: 5px 60px;
    margin-bottom: 0px;
}
.toolbox-list > li {
    min-width: 100px;
    padding: 0 24px;
    padding-top: 0;
    height: 88px;
    border-right: 1px solid #3e3d3d;
    list-style-type: none;
}
.no-padding {
    margin: 0;
}
.no-padding div {
    padding: 2px;
}
.no-padding div select {
    width: 100%;
    outline: none;
    background-color: #2c3752;
    color: #fff;
    border-radius: 3px;
    border: none;
    font-size: 14px;
    padding-top: 9px;
    padding-bottom: 9px;
    padding-left: 5px;
}
.checkbox-btn input {
    display: none;
}
.formating-icons {
    float: left;
    padding: 0;
    position: relative;
}
.placeholder-box {
    color: #fff;
    font-size: 12px;
    border: 1px solid #3e3d3d;
    padding: 4px 10px !important;
    border-radius: 22px;
    cursor: pointer;
}
.placeholder-b {
    color: #fff;
    font-size: 12px;
    border: 1px solid #3e3d3d;
    padding: 4px 10px !important;
    border-radius: 22px;
    cursor: pointer;
    background-color: #3e3d3d;
}
.placeholder-box:hover {
    background-color: #3e3d3d;
}
.checkmark {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
    color: #fff;
    cursor: pointer;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    user-select: none;
    background-color: transparent;
    text-align: center;
    vertical-align: middle;
    display: inline-block;
    font-weight: 400;
}
.checkbox-btn:hover input ~ .checkmark {
    color: #fff;
    background-color: #3e3d3d;
}
.checkbox-btn input:checked ~ .checkmark {
    color: #fff;
    background-color: #3e3d3d;
}
.text-shadow {
    -webkit-filter: drop-shadow(0px 1px 2px rgba(255, 255, 255, 0.7));
    filter: drop-shadow(rgba(255, 255, 255, 0.7) 0px 1px 2px);
}
.auto-templates * {
    color: #fff;
}
.icon-box {
    height: 50px;
    background: #ffffff;
    text-align: center;
    margin-bottom: 20px;
    border-radius: 3px;
}
.template-item {
    border: 1px solid #3e3d3d;
    border-radius: 5px;
    padding: 10px !important;
    margin-bottom: 5px;
    margin-right: 5px;
}
.template-item.selected {
    background: rgb(143, 211, 243);
    border: 1px solid rgb(23, 135, 187);
}
.template-item.selected h4,
.template-item.selected span {
    color: #000;
    font-weight: 500 !important;
}
.template-item h4 {
    font-size: 14px;
    font-weight: 300 !important;
    margin-bottom: 0;
}
.template-item span {
    font-size: 10px;
    color: #949292;
}
.margin-icon {
    width: 100%;
    text-align: center;
    margin-top: 5px;
    color: #cac9c9;
}
.inputs * {
    color: #fff;
}
/* context menu css */
#contextMenuContainer {
    display: none;
    position: fixed;
    background: #3e3d3d;
    z-index: 100000;
}
#colorContextMenu {
    display: none;
    position: fixed;
    background: var(--brand-accent-dark);
    z-index: 100000;
}
#contextMenuContainer ul {
    padding-left: 0;
    width: 150px;
    margin: 8px 0px;
}
#contextMenuContainer ul li {
    list-style-type: none;
    padding: 2px 10px;
    color: #fff;
    font-size: 12px;
    cursor: pointer;
    position: relative;
}
#contextMenuContainer ul li ul {
    position: absolute;
    display: none;
    top: 10px;
    left: 50px;
    background: #82797c;
    z-index: 100;
}
#contextMenuContainer ul li:hover ul {
    display: block;
}
#contextMenuContainer ul li:hover {
    background: #5a5959;
}
.placeholder-wrapper input {
    width: 100%;
    margin-bottom: 10px;
}
.placeholder-wrapper p,
.placeholder-wrapper label {
    color: #fff;
}
.header_content,
.footer_content {
    min-height: 96px;
    width: 100%;
    opacity: 0.5;
    border: none;
    outline: none;
}
.header_active {
    opacity: 1;
    border-bottom: 1px dashed #999;
}
.footer_active {
    opacity: 1;
    border-top: 1px dashed #999;
}
/* table related css */
.table-auto tr td {
    padding: 8px 8px;
}
.table-auto {
    margin-bottom: 0;
}
.table-bordered th,
.table-bordered td {
    border: 1px solid #757272;
}

// table td , table th{
//   padding: 0px !important;
// }
.main--tippy--options {
    background: #f7f7f7;
    width: 300px;
    height: 100px;
    margin-top: 100px;
    margin-left: 100px;
}

.custom-pickr-class {
    padding-top: 100px;
}
// .neo--table th , .neo--table td{
//   padding: 4px;

// }

@media screen and (max-width: 1839px) {
    .content-section {
        margin-top: 17em !important;
    }
}

@media screen and (max-width: 1059px) {
    .content-section {
        margin-top: 28em !important;
    }
}
</style>
