import Button from "@/components/button";
import axios from "axios";
import DeleteModal from "@/components/delete-modal";
import {baseBlackboxUrl} from "../../main";
import TabComp from "@/components/tab";
import {mapGetters, mapMutations} from "vuex";

export default {
    name: "saved-template-sidebar",
    components: {
        "neo-button": Button,
        "delete-modal": DeleteModal,
        "neo-tab": TabComp,
    },
    props: ["tab"],
    data() {
        return {
            search: "",
            BASE_URL: baseBlackboxUrl,
            items: [],
            tabs: [
                {
                    key: "sections",
                    name: "Sections",
                    enabled: true,
                    readOnly: false,
                },
                {
                    key: "reports",
                    name: "Reports",
                    enabled: true,
                    readOnly: false,
                },
            ],
            currentTab: "sections",
        };
    },
    computed: {
        templates: {
            get() {
                return this.items.filter((item) => {
                    return item.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
                });
            },
            set(newValue) {
                this.items = newValue;
            },
        },
        ...mapGetters(["sections", "reportTemplates"]),
    },
    mounted() {
        this.currentTab = "sections";
    },
    methods: {
        ...mapMutations(["SET_SECTIONS"]),
        handleDelete(id, name) {
            const props = {
                label: "Delete Template",
                inputLabel: "Type the name of the template to confirm deletion:",
                inputPlaceHolder: name,
                id,
            };
            this.$modal.show("delete-modal", props);
        },
        async handleDeleteTemplate(id) {
            let loading = this.$loading.show({zIndex: 10000000});
            let url = `${this.BASE_URL}/api/v1/reports/${id}`;
            await axios.delete(url, {
                headers: {
                    Authorization: "Basic bmVvdXNlcjpub3RhZGVtb0AxMjM=",
                },
            });
            this.items = this.items.filter((n) => n.id !== id);
            this.SET_SECTIONS(this.items);
            loading.hide();
            this.$modal.hide("delete-modal");
        },
    },
    watch: {
        currentTab() {
            this.items = this.currentTab == "sections" ? this.sections : this.reportTemplates;
        },
        sections() {
            this.items = this.currentTab == "sections" ? this.sections : this.reportTemplates;
        },
    },
};
