import Input from "@/components/input";
import Button from "@/components/button";
export default {
    name: "delete-modal",
    components: {
        "neo-input": Input,
        "neo-button": Button,
    },
    props: [],
    data() {
        return {
            label: "",
            inputLabel: "",
            inputPlaceholder: "",
            inputValue: "",
            id: "",
            error: false,
        };
    },
    computed: {},
    mounted() {},
    methods: {
        handleBeforeOpen({params}) {
            this.label = params.label;
            this.inputLabel = params.inputLabel;
            this.inputPlaceholder = params["inputPlaceHolder"];
            this.id = params["id"];
        },
        handleDelete() {
            if (this.inputValue === this.inputPlaceholder) {
                this.$emit("deleteTemplate", this.id);
            } else {
                this.error = true;
            }
        },
        handleBeforeClose() {
            this.label = "";
            this.inputLabel = "";
            this.id = "";
            this.inputPlaceHolder = "";
            this.error = false;
            this.inputValue = "";
        },
    },
};
