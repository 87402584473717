<template>
    <div class="s">
        <div class="container" id="printableDiv">
            <div id="nav" class="noprint">
                <!-- <router-link to="/home">Home</router-link> -->
            </div>
            <!--<button
        v-b-toggle.saved_templates
        class="item-btn noprint"
        @click="showEditorOptions = false"
      >
        <b-icon icon="plus" font-scale="2"></b-icon>
      </button> -->

            <div class="report--builder--options" v-if="showEditorOptions">
                <div class="editor">
                    <router-link to="/editor">
                        <!-- <div class="tw-flex tw-bg-white tw-p-4 tw-my-6 tw-mx-8 tw-h-36 tw-text-black tw-shadow tw-rounded hover:tw-shadow-md">
              <img :src="require('@/assets/icons/icon-editor.svg')" class="tw-w-16"/>
              <div class="tw-flex tw-flex-col tw-ml-6 tw-justify-center">
                <div class="tw-font-bold">OPEN EDITOR</div>
                <div style="color: rgb(120, 131, 167)">Template editor</div>
              </div>
            </div> -->
                        <div class="tw-flex tw-flex-col tw-items-center tw-border-transparent tw-px-6 tw-bg-white tw-shadow tw-rounded hover:tw-shadow-md">
                            <div class="tw-text-xl tw-my-6 tw-font-medium" style="color: #3d9ae2">
                                Section Creator
                            </div>
                            <img :src="require('@/assets/icons/icon-editor.svg')" class="tw-w-20" />
                            <div class="tw-text-3xl tw-my-6 tw-mr-4 tw-font-light" style="color: #a6acb1">
                                Section
                            </div>
                            <div class="tw-mb-6 tw-text-center" style="color: #6eb3e9">
                                This allows to create report sections
                            </div>
                        </div>
                    </router-link>
                </div>
                <!-- <div class="seperator"></div> -->
                <div class="editor">
                    <div class="tw-cursor-pointer" @click="handleShowTemplate">
                        <!-- <div
              class="tw-flex tw-bg-white tw-p-4 tw-my-6 tw-mx-8 tw-h-36 tw-text-black tw-shadow tw-rounded hover:tw-shadow-md"
            >
              <img
                :src="require('@/assets/icons/icon-reports.svg')"
                class="tw-w-16"
              />
              <div class="tw-flex tw-flex-col tw-ml-6 tw-justify-center">
                <div class="tw-font-bold">REPORT TEMPLATES</div>
                <div style="color: rgb(120, 131, 167)">Report editor</div>
              </div>
            </div> -->
                        <div class="tw-flex tw-flex-col tw-items-center tw-border-transparent tw-px-6 tw-bg-white tw-shadow tw-rounded hover:tw-shadow-md">
                            <div class="tw-text-xl tw-my-6 tw-font-medium" style="color: #3d9ae2">
                                Template Creator
                            </div>
                            <img :src="require('@/assets/icons/icon-template.svg')" class="tw-w-20" />
                            <div class="tw-text-3xl tw-my-6 tw-mr-4 tw-font-light" style="color: #a6acb1">
                                Template
                            </div>
                            <div class="tw-mb-6 tw-text-center" style="color: #6eb3e9">
                                This allows to create report templates
                            </div>
                        </div>
                    </div>
                </div>
                <div class="editor">
                    <router-link to="/template">
                        <!-- <div
              class="tw-flex tw-bg-white tw-p-4 tw-my-6 tw-mx-8 tw-h-36 tw-text-black tw-shadow tw-rounded hover:tw-shadow-md"
            >
              <img
                :src="require('@/assets/icons/icon-reports.svg')"
                class="tw-w-16"
              />
              <div class="tw-flex tw-flex-col tw-ml-6 tw-justify-center">
                <div class="tw-font-bold">REPORT TEMPLATES</div>
                <div style="color: rgb(120, 131, 167)">Report editor</div>
              </div>
            </div> -->
                        <div class="tw-flex tw-flex-col tw-items-center tw-border-transparent tw-px-6 tw-bg-white tw-shadow tw-rounded hover:tw-shadow-md">
                            <div class="tw-text-xl tw-my-6 tw-font-medium" style="color: #3d9ae2">
                                Report Creator
                            </div>
                            <img :src="require('@/assets/icons/icon-reports.svg')" class="tw-w-20" />
                            <div class="tw-text-3xl tw-my-6 tw-mr-4 tw-font-light" style="color: #a6acb1">
                                Report
                            </div>
                            <div class="tw-mb-6 tw-text-center" style="color: #6eb3e9">
                                This allows to create reports
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
            <!-- <button class="item-btn print-btn noprint" v-on:click="printPage">
      <b-icon icon="printer" font-scale="1.5"></b-icon>
    </button> -->
            <button class="item-btn noprint" v-if="rendering_templates.length > 0" v-on:click="askForName">
                <b-icon icon="file-earmark-arrow-up-fill" font-scale="1.5"></b-icon>
            </button>
            <!-- <b-sidebar id="saved_templates" title="Templates" backdrop shadow :backdrop-variant="backdrop_color">
      <input type="search" class="search" placeholder="Search Template" v-model="search">
      <div class="px-3 py-2" v-for="(template, i) in filteredItems" :key="template.id" v-on:click="selectTemplate(i)">
        <p>{{template.name}}</p>
        <b-img :src="template.thumbnail" fluid thumbnail></b-img>
      </div>
    </b-sidebar> -->
            <saved-template-sidebar :filteredItems="filteredItems" :savedTemplates="templates" @selectTemplate="selectTemplate($event)" @updateInput="search = $event" :tab="true" />
            <NeoEditorToolBar :editor="editor" :filesave="false" v-if="!showEditorOptions" />
            <div class="page_container">
                <draggable v-model="rendering_templates" ref="dragger">
                    <div v-for="(raw, i) in rendering_templates" :key="i" class="tempplate_container">
                        <div class="tools noprint">
                            <div class="btn-group" role="group" aria-label="Basic example">
                                <button type="button" class="btn btn-info">
                                    <b-icon icon="arrows-expand" font-scale="1"></b-icon>
                                </button>
                                <button type="button" class="btn btn-danger" v-on:click="deleteTemplate(i)">
                                    <b-icon icon="trash" font-scale="1"></b-icon>
                                </button>
                            </div>
                        </div>
                        <div v-html="raw.html" v-on:change="changes" style="width: 794px; margin: 0 auto"></div>
                    </div>
                    <!-- <div v-html="checkhtml"></div> -->
                    <!-- <TemplateRenderer  :key="index" :raw_html="raw" :ref_name="'ref'+index"/> -->
                </draggable>
            </div>
        </div>
    </div>
</template>

<script>
// import TemplateRenderer from '../components/TemplateRenderer';
import draggable from "vuedraggable";
import {baseBlackboxUrl} from "../../main";
import Editor from "./editor.js";
import {Sketch} from "vue-color";
import SavedTemplateSidebar from "@/components/saved-template-sidebar";
import {mapActions, mapGetters} from "vuex";
import NeoEditorToolBar from "./toolbar";
import {DiffDOM, nodeToObj} from "diff-dom";

export default {
    name: "index",
    components: {
        // TemplateRenderer,
        "sketch-picker": Sketch,
        draggable,
        SavedTemplateSidebar,
        NeoEditorToolBar,
    },
    data() {
        return {
            elementA: "",
            elementB: "",
            undoStates: [],
            redoStates: [],
            ctrlDown: false,
            shiftDown: false,
            zDown: false,
            updateDOM: null,
            headerFooterBgs: {
                header: "#FFFFFF",
                footer: "#FFFFFF",
            },
            showEditorOptions: true,
            search: "",
            settings: false,
            backdrop_color: "transparent",
            rendering_templates: [],
            ref_name: "",
            selectedImg: null,
            listener: null,
            imgPath: "",
            height: "auto",
            settingType: "none",
            checkhtml: "",
            templates: [],
            BASE_URL: baseBlackboxUrl,
            STAGING_URL: baseBlackboxUrl,
            editor: null,
            bgColor: "#343a40",
            tableProperties: {
                width: 100,
                border: {
                    width: 1,
                    color: "#dee2e6",
                    type: "solid",
                },
            },
        };
    },
    methods: {
        ...mapActions(["fetchAllSections"]),
        keyUpEvent(e) {
            if (e.keyCode === 17 || e.keyCode === 91) this.ctrlDown = false;
            if (e.keyCode === 16) this.shiftDown = false;
            if (e.keyCode === 90) {
                if (this.shiftDown && this.ctrlDown && this.redoStates.length) {
                    this.redo();
                } else if (this.ctrlDown && this.undoStates.length) {
                    this.undo();
                }
                this.zDown = false;
            }
        },
        keyDownEvent(e) {
            if (e.keyCode === 17 || e.keyCode === 91) this.ctrlDown = true;
            if (e.keyCode === 16) this.shiftDown = true;
            if (e.keyCode === 90) {
                this.zDown = true;
                if (this.shiftDown && this.ctrlDown) {
                    e.preventDefault();
                    e.stopPropagation();
                    e.cancelBubble = true;
                } else if (this.ctrlDown) {
                    e.preventDefault();
                    e.stopPropagation();
                    e.cancelBubble = true;
                }
            }
        },
        updateDomDiff() {
            clearInterval(this.updateDOM);
            const dd = new DiffDOM();
            this.elementB = nodeToObj(document.getElementById("editor"));
            let diff = dd.diff(this.elementA, this.elementB);
            if (diff && diff.length) {
                this.undoStates.push(diff);
                this.elementA = nodeToObj(document.getElementById("editor"));
            }
            this.updateDOM = setInterval(this.updateDomDiff, 2000);
        },
        undo() {
            let element = this.undoStates.pop();
            const dd = new DiffDOM();
            dd.undo(document.getElementById("editor"), element);
            this.redoStates.push(element);
            this.elementA = nodeToObj(document.getElementById("editor"));
        },
        redo() {
            let element = this.redoStates.pop();
            const dd = new DiffDOM();
            dd.undo(document.getElementById("editor"), element);
            this.undoStates.push(element);
            this.elementA = nodeToObj(document.getElementById("editor"));
        },
        showTemplates: function() {
            this.showEditorOptions = true;
        },
        selectTemplate: function(data) {
            let that = this;
            if (data.currentTab == "reports") {
                data.template.body.forEach((ele) => {
                    this.rendering_templates.push(this.templates.find((template) => template.id === ele));
                    this.$nextTick(() => {
                        this.addEventListener();
                    });
                });
            } else {
                this.rendering_templates.push(this.templates.find((template) => template.id === data.template.id));
                this.$nextTick(() => {
                    this.addEventListener();
                });
            }
            // this.editor = new Editor("editor", { dragger: true, ui: false });
            setTimeout(() => {
                this.editor = new Editor("editor", {
                    dragger: true,
                    ui: false,
                    headersFootersAllowEdit: false,
                });
                document.getElementById("editor").onkeydown = function(e) {
                    that.keyDownEvent(e);
                };
                document.getElementById("editor").onkeyup = function(e) {
                    that.keyUpEvent(e);
                };

                that.updateDOM = setInterval(() => {
                    that.elementA = nodeToObj(document.getElementById("editor"));
                    that.updateDomDiff();
                }, 2000);
                // this.editor.reattachEvents({
                //   dragger: false,
                //   headersFootersAllowEdit: false,
                // });
                this.editor.fixConstraints();
                this.editor.reattachIconEvents();
                var pages = [...document.querySelectorAll(".neo-page")];
                pages.forEach((page) => {
                    this.attachRemovableOptionToPage(page);
                });
            }, 2000);
        },
        deleteTemplate: function(i) {
            this.rendering_templates.splice(i, 1);
            // if (this.rendering_templates.length == 0) {
            //   this.showEditorOptions = true;
            // }
        },
        changes() {},
        printPage: function() {
            window.print();
        },
        clickContextItem: function(e) {
            let clicked = e.target.getAttribute("value");
            if (clicked == "perperties") {
                this.$refs["table_properties"].show();
            } else {
                this.editor.clickContextItem(e, clicked);
            }
        },
        clickContextItemChild: function(e) {
            this.editor.clickContextItemChild(e);
        },
        onFileSelected: function(event) {
            var selectedFile = event.target.files[0];
            var reader = new FileReader();
            let that = this;
            if (that.selectedImg && selectedFile) {
                that.selectedImg.title = selectedFile.name;
                reader.onload = function(event) {
                    that.imgPath = event.target.result;
                };

                reader.readAsDataURL(selectedFile);
            }
        },
        addEventListener() {
            let that = this;
            if (this.listener == null) {
                this.listener = this.$refs["dragger"]["$el"].addEventListener("click", function(event) {
                    // event.preventDefault();
                    let selection = window.getSelection ? window.getSelection() : document.selection.createRange().text;

                    if (event.target.tagName == "IMG") {
                        that.settings = true;
                        that.selectedImg = event.target;
                        that.settingType = "image";
                    } else if (selection.type != "None") {
                        that.settings = true;
                        that.settingType = "text";
                        if (selection.baseNode != null) {
                            let selectedElem = selection.baseNode.parentNode;
                        }
                    }
                });
            }
            let pages = document.getElementsByClassName("page_container");
            for (let p = 0; p < pages.length; p++) {
                pages[p].style.minHeight = this.height;
            }
            // let tables = document.getElementsByTagName("table");
            // for (let i = 0; i < tables.length; i++) {
            //   if (tables[i].parentNode.children.length == 1) {
            //     let btn = document.createElement("button");
            //     btn.innerText = "Add Row";
            //     btn.className = "add_row_btn";
            //     tables[i].parentNode.appendChild(btn);
            //     btn.addEventListener("click", function () {
            //       let row = tables[i].tBodies[0].children[
            //         tables[i].tBodies[0].children.length - 1
            //       ].cloneNode(true);
            //       tables[i].tBodies[0].appendChild(row);
            //     });
            //   }
            // }
        },
        askForName: function() {
            var dialogInput = prompt("Enter Report Name");
            if (dialogInput != null) {
                this.saveTemplate(dialogInput);
            }
        },
        saveTemplate: function(name) {
            let url = this.STAGING_URL + "/api/v1/template";
            let selectedTemplates = {
                name: name,
                body: this.rendering_templates.map((temp) => {
                    return temp.id;
                }),
            };
            fetch(url, {
                method: "POST",
                headers: {
                    Authorization: "Basic bmVvdXNlcjpub3RhZGVtb0AxMjM=",
                },
                body: JSON.stringify(selectedTemplates),
            })
                .then((response) => response.json())
                .then((data) => {
                    alert("Report Saved Successfully");
                })
                .catch((error) => {});
        },
        getSavedTemplates: async function() {
            await this.fetchAllReportTemplates();
            this.templates = this.sections;
            let template = {
                name: "Page Break",
                desc: "Page break",
                html: "<div class='pagebreak' contenteditable='false'><br></div>",
                thumbnail: "https://www.elegantthemes.com/blog/wp-content/uploads/2019/10/divi-custom-global-footer-featured-image-3.jpg",
            };
            this.templates.push(template);
        },
        ...mapActions(["fetchAllReportTemplates"]),
        handleShowTemplate() {
            // this.editor = new Editor("printableDiv", { true false, ui: false });
            // this.editor.init();
            // this.editor.reattachIconEvents();
            this.showEditorOptions = false;
            document.getElementById("saved_templates").style.display = "flex";
        },
    },
    computed: {
        filteredItems() {
            return this.templates.filter((item) => {
                return item.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
            });
        },
        ...mapGetters(["sections", "reportTemplates"]),
    },
    mounted() {
        var that = this;
        document.querySelector(".b-sidebar-backdrop").addEventListener("click", function(e) {
            if (that.rendering_templates.length == 0) {
                that.showEditorOptions = true;
            }
        });

        that.fetchAllReportTemplates();

        const saved_templates = document.getElementById("saved_templates").classList;
        saved_templates.remove("pt-5");
        saved_templates.add("pt-2");

        this.height = window.innerHeight + "px";
        this.getSavedTemplates();
    },
};
</script>
<style lang="scss" scoped>
::v-deep {
    @import "~bootstrap/scss/bootstrap.scss";
    @import "~bootstrap-vue/src/index.scss";
}
.s {
    min-height: 100%;
    background: #f8f9fb;
}
#file1 {
    padding: 3px;
}

.tempplate_container .btn {
    border-radius: 0;
}
.fileSelect {
    padding: 10px;
}
.fileSelect img {
    width: 100%;
}
.item-btn {
    position: fixed;
    bottom: 40px;
    right: 40px;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    border: 0px;
    background-color: #6c7ae0;
    color: #fff;
    box-shadow: 0 0px 40px 0px #6c79e0e3;
    -moz-box-shadow: 0 0px 40px 0px #6c79e0e3;
    -webkit-box-shadow: 0 0px 40px 0px #6c79e0e3;
    -o-box-shadow: 0 0px 40px 0px #6c79e0e3;
    -ms-box-shadow: 0 0px 40px 0px #6c79e0e3;
    outline: none;
    padding-top: 3px;
}
.print-btn {
    bottom: 110px;
}
.save-btn {
    bottom: 110px;
}
* {
    -webkit-print-color-adjust: exact;
}
.page_container {
    background: #f8f9fb;
    margin-top: 20px;
    margin-bottom: 20px;
    overflow: hidden;
    margin-top: 10rem;
}
.img_thumb {
    float: left;
    width: 300px;
    margin-right: 10px;
}
.search {
    width: 90%;
    height: 35px;
    padding-left: 15px;
    padding-right: 15px;
    outline: none;
    border: 1px solid #ccc;
    border-radius: 50px;
    margin-left: 15px;
}
.tempplate_container {
    position: relative;
}
.tempplate_container:hover .tools {
    display: block;
}
.tools {
    position: absolute;
    top: 0;
    right: 161px;
    display: none;
    z-index: 40;
}

// @media print {
//   .editor_parent {
//     border: none;
//   }
//   .noprint {
//     display: none;
//   }
//   .resizable {
//     resize: none !important;
//   }
//   .tempplate_container {
//     border: none;
//   }
//   .container {
//     max-width: 100% !important;
//     padding: 0 !important;
//   }
//   .page_container {
//     padding: 0px;
//     margin: 0px;
//   }
// }

.editor--link {
    width: auto;
    min-height: 8rem;
    height: 100%;
    background-color: #ffffff;
    border-radius: var(--container-border);
    margin: 1.5rem 2rem;
    box-shadow: var(--box-elevation);
    cursor: pointer;
    transition: var(--transition-mnml);
    padding: 1rem;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    &:hover {
        box-shadow: var(--box-elevation-on-focus);
    }

    .icon {
        width: 4rem;
        margin: 1rem 2rem;
        opacity: 0.56;
    }

    .content {
        p {
            line-height: 24px;
            color: var(--text-fade-color);
        }
        margin: 0 auto;

        h3 {
            font-size: 0.9em;
            font-weight: 600;
        }

        span {
            display: flex;
            align-items: center;

            img {
                width: 1rem;
                margin: 0 1rem 0 0;
            }
        }
    }
}

.report--builder--options {
    a {
        text-decoration: none;
    }
    display: flex;
    padding-top: 60px;
    padding-bottom: 60px;
    border-radius: 10px;
    transform: translateY(50%);
    gap: 4em;
    .editor {
        flex: 1;
    }
}
/*.report--builder--options > .editor {
  flex: 1;
  height: 200px;
}
.report--builder--options > .seperator {
  width: 1px;
  background: #e8e8e8;
}
.report--builder--options > .templates {
  flex: 1;
}*/

#contextMenuContainer {
    display: none;
    position: fixed;
    background: #3e3d3d;
    z-index: 100000;
}
#colorContextMenu {
    display: none;
    position: fixed;
    background: var(--brand-accent-dark);
    z-index: 100000;
}
#contextMenuContainer ul {
    padding-left: 0;
    width: 150px;
    margin: 8px 0px;
}
#contextMenuContainer ul li {
    list-style-type: none;
    padding: 2px 10px;
    color: #fff;
    font-size: 12px;
    cursor: pointer;
    position: relative;
}
#contextMenuContainer ul li ul {
    position: absolute;
    display: none;
    top: 13px;
    left: 50px;
    background: #82797c;
    z-index: 100;
}
#contextMenuContainer ul li:hover ul {
    display: block;
}
#contextMenuContainer ul li:hover {
    background: #5a5959;
}
::v-deep .b-sidebar > .b-sidebar-header {
    display: none;
}
@media screen and (max-width: 1528px) {
    .page_container {
        margin-top: 23em !important;
    }
}

@media screen and (max-width: 1059px) {
    .page_container {
        margin-top: 28em !important;
    }
}
::v-deep {
    .toolbox {
        left: 17em;
        // margin-left: 4em;
        margin-top: 0;
        top: 5.7em;
        .list-group {
            margin-left: 2rem;
            li:nth-child(5) {
                padding: 0;
            }
        }
    }
}
</style>
